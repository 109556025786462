import Sample from "../Components/Sample";
import SampleCard from "../Components/SampleCard";
import ScanQr from "../Components/ScanQr";
import DataTablesContain from "../Components/Tables/DataTable";

export const routes = [
  { path: `${process.env.PUBLIC_URL}/scanqr`, Component: <ScanQr /> },
  { path: `${process.env.PUBLIC_URL}/samplepage`, Component: <SampleCard /> },
  { path: `${process.env.PUBLIC_URL}/sample`, Component: <DataTablesContain /> },
  // { path: `${process.env.PUBLIC_URL}/sample`, Component: <Sample /> },
];
