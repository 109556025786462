import React from 'react'
import { FolderPlus } from 'react-feather';
import HeaderDashboard from './HeaderDashboard';
import Appliacation from './Application';
import Pages from './Pages';
import { Link } from 'react-router-dom'
import { UL, LI } from '../../../AbstractElements';
import { Image } from "../../../AbstractElements";

const LeftHeader = () => {
  const mystyle = {
    width: 180
  };
  return (
    <div className="left-header col horizontal-wrapper ps-0">
      <div className=" simple-list left-menu-header">
        <UL attrUL={{ className: "app-list" }}>
          <img src={`${require('../../../assets/images/static/mock-images/logos/bytepassnamelogodark.png')}`} style={mystyle} alt="photoURL" className="img-fluid for-light" />
          <img src={`${require('../../../assets/images/static/mock-images/logos/bytepassnamelogolight.png')}`} style={mystyle} alt="photoURL" className="img-fluid for-dark" />
          
          {/* <Image attrImage={{ className: 'img-fluid for-light img-100', src: `${require('../../../assets/images/static/mock-images/logos/bytepassnamelogodark.png')}`, alt: 'logo' }} />
          <Image attrImage={{ className: 'img-fluid for-dark img-100', src: `${require('../../../assets/images/static/mock-images/logos/bytepassnamelogolight.png')}`, alt: 'logo' }} /> */}
          {/* <LI attrLI={{ className: "onhover-dropdown" }}>
            <div className="app-menu">
              <FolderPlus />
            </div>
            <UL attrUL={{ className: "simple-list onhover-show-div left-dropdown" }}>
              <LI><Link to={`${process.env.PUBLIC_URL}/scanqr`}>File Manager</Link></LI>
              <LI><Link to={`${process.env.PUBLIC_URL}/scanqr`}> Social App</Link></LI>
              <LI><Link to={`${process.env.PUBLIC_URL}/scanqr`}> Bookmark</Link></LI>
            </UL>
          </LI> */}
        </UL>
        {/* <UL attrUL={{ className: "simple-list header-left d-flex" }}   >
          <HeaderDashboard />
          <Appliacation />
          <Pages />
        </UL> */}
      </div>
    </div>
  )
}

export default LeftHeader