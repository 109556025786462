import { Layers, Home, Camera, Mail } from "react-feather";

export const MENU = [
  {
    className: "menu-box",
    menu: [
      {
        url: `${process.env.PUBLIC_URL}/sample`,
        icon: <Home />,
        title: "Generated Passes",
        type: "link",
      },
      {
        url: `${process.env.PUBLIC_URL}/scanqr`,
        icon: <Camera />,
        title: "Scan Qr",
        type: "link",
      },
      {
        url: `${process.env.PUBLIC_URL}/samplepage`,
        icon: <Mail />,
        title: "Contact Us",
        type: "link",
      },
      // {
      //   title: "sample",
      //   icon: <Home />,
      //   class: "lan-3",
      //   menu: [
      //     {
      //       title: "sample",
      //       url: `${process.env.PUBLIC_URL}/sample`,
      //       class: "lan-4",
      //       type: "link",
      //     },
      //   ],
      // },
    ],
  },
  // {
  //   className: "menu-box",
  //   menu: [
  //     {
  //       url: `https://vmrfdu.edu.in/`,
  //       icon: <Layers />,
  //       title: "Documentation",
  //       type: "link",
  //     },
  //     {
  //       url: `https://vmrfdu.edu.in/`,
  //       icon: <Layers />,
  //       title: "Support",
  //       type: "link",
  //     },
  //   ],
  // },
];

export const SEARCHMENU = [
  {
    className: "menu-box",
    menu: [
      {
        url: `${process.env.PUBLIC_URL}/samplepage`,
        icon: <Layers />,
        title: "SamplePage",
        type: "link",
      },
      {
        title: "sample",
        icon: <Home />,
        class: "lan-3",
        menu: [
          {
            title: "sample",
            url: `${process.env.PUBLIC_URL}/sample`,
            class: "lan-4",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    className: "menu-box",
    menu: [
      {
        url: `https://vmrfdu.edu.in/`,
        icon: <Layers />,
        title: "Documentation",
        type: "link",
      },
      {
        url: `https://vmrfdu.edu.in/`,
        icon: <Layers />,
        title: "Support",
        type: "link",
      },
    ],
  },
];
