import { Layers, Home, Camera, Mail, FileText } from "react-feather";
import React, { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Footer, P } from '../../AbstractElements';
import { NavLink } from 'react-router-dom';

const FooterComponent = () => {
  const isActive = (path) => window.location.pathname === path;
  return (
    <Fragment>
      <Footer attrFooter={{ className: "footer" }}>
        <Container fluid={true}>
          <Row>
            <Col md="6" className='p-0 footer-left d-none d-md-block'>
              <P attrPara={{ className: "mb-0" }}>Copyright © 2023 VMRF. All rights reserved.</P>
            </Col>
            <Col md="6" className='p-0 footer-right d-none d-md-block'>
              <P attrPara={{ className: "mb-0 ms-3" }}>Made with <i className="fa fa-heart font-danger"></i> by Informatics - AVMC</P>
            </Col>
            {/* Mobile View */}
            <Col xs="12" className='p-0 footer-mobile d-md-none'>
              <div className="icon-container">
                <NavLink to="/sample" className={`icon ${isActive('/sample') ? 'active' : ''}`}><Home /></NavLink>
                <NavLink to="/scanqr" className={`icon ${isActive('/scanqr') ? 'active' : ''}`}><Camera /></NavLink>
                <NavLink to="/samplepage" className={`icon ${isActive('/samplepage') ? 'active' : ''}`}><FileText /></NavLink>
                <NavLink to="/samplepage" className={`icon ${isActive('/samplepage') ? 'active' : ''}`}><Mail /></NavLink>
              </div>
            </Col>
          </Row>
        </Container>
      </Footer>
    </Fragment>
  )
}

export default FooterComponent;
