import React, { Fragment } from 'react'
import { Card, CardBody, Container } from 'reactstrap'
import { Breadcrumbs, H4 } from '../../AbstractElements'

const Index = () => {
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Contact Us" parent="Home" title="Contact Us" />
      <Container fluid={true}>
        <div className="row">
          <div className="col-sm-12">
            <Card>
              <div className="card-header pb-0">
                <H4>BytePass - Smart Canteen System</H4>
              </div>
              <CardBody>
                <p className="mb-0">We, <b>Informatics Department</b> at Aarupadai Veedu Medical College - VMRF University! We take pride in introducing our innovative Smart Canteen Authentication System, designed to streamline the dining experience.</p>
                <br />
                <p className="mb-0">Our system combines a user-friendly application with a cutting-edge device featuring a QR code. Users can effortlessly access the canteen and enjoy delicious meals by simply scanning the QR code on the device.</p>
                <br />
                <p className="mb-0">For inquiries and support, please contact us at:</p>
                <ul>
                  <li>Email: <a href="mailto:informatics@avmc.edu.in">informatics@avmc.edu.in</a></li>
                  <li>Mobile: +91 87786 35626</li>
                </ul>
              </CardBody>
            </Card>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default Index