import React from "react";
import Routers from "./Route";
import CustomizerProvider from "./Helper/Customizer/CustomizerProvider";
import AnimationThemeProvider from "./Helper/AnimationTheme/AnimationThemeProvider";
import BookmarkProvider from "./Helper/bookmark/BookmarkProvider";
import ProductProvider from "./Helper/product/ProductProvider";

const App = () => {
  return (
    <div className="App">
      <CustomizerProvider>
        <AnimationThemeProvider>
          <BookmarkProvider>
            <ProductProvider>
              <Routers />
            </ProductProvider>
          </BookmarkProvider>
        </AnimationThemeProvider>
      </CustomizerProvider>
    </div>
  );
};

export default App;
