import React, { Fragment, useState, useCallback } from 'react'
import { Card, CardBody, Container, Modal, ModalBody, ModalHeader, ModalFooter, Row, Button, Badge } from 'reactstrap'
import { Breadcrumbs, H4 } from '../../AbstractElements'
import { toast } from 'react-toastify';
import { Btn, P } from '../../AbstractElements';
import QrReader from "react-qr-scanner"
import QRCode from "react-qr-code";
import PWAInstallPrompt from "../../PWAInstallPrompt";
import imgg from '../../assets/images/static/icons/approved.png';
import userimgg from '../../assets/images/static/others/ganesan.jpg';

const Index = () => {

  const [result, setResult] = useState();
  const [modal, setModal] = useState(false);
  const toggle = useCallback(() => setModal(!modal), [modal]);
  let handleScan = data => {
    if (data) {
      // toggle();
      setModal(true)
      setResult(data?.text);
    }
  };

  let handleError = err => {
    // alert(err);
  };

  const mystyle = {
    width: 150
  };

  return (
    <Fragment>
      {/* <PWAInstallPrompt /> */}
      <Modal isOpen={modal} toggle={toggle} className="ticket-modal" centered={true}>
        <ModalBody>
          <Container fluid={true} className="ticket-container">
            <img src={`${imgg}`} alt="User" className="user-image" />
            <div className="ticket">
              <div className="ticket-section left-section">
                <p className="ticket-date">MONDAY, 19TH FEB 2024</p>
                <div className="ticket-image">
                  <img src={`${userimgg}`} alt="User" />
                </div>
                <Row>
                  <p className="ticket-number">BytePass Id: AVMC-456-1902</p>
                  <p className="ticket-number">Name: Ganesan S</p>
                  <p className="ticket-number">User: <Badge color="success" pill>STUDENT</Badge></p>
                  <p className="ticket-number">Department: MBBS 2nd Year Community Medicine</p>
                </Row>
              </div>
              <div className="ticket-section right-section">
                <p className="ticket-date">1.15 PM to 2.30 PM</p>
                <div className="barcode">
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "75%", width: "100%" }}
                    value={"Ticket Test data"}
                    viewBox={`0 0 256 256`}
                  />
                </div>
                <Row>
                  <p className="ticket-number"><b>{"Your BytePass is valid only between 1.15 PM to 2.30 PM"}</b></p>
                  <p className="ticket-number"><Badge color="warning" pill>ONE TIME PASS</Badge></p>
                  <p className="ticket-invite">ENJOY YOUR MEAL!</p>
                </Row>
              </div>
            </div>
            <Row className="ticket-footer">
              <Button color="primary" onClick={toggle}>Close</Button>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
      <Breadcrumbs mainTitle="Scan QR" parent="Apps" title="Scan QR" />
      <Container fluid={true}>
        <div className="row">
          <div className="col-sm-12">
            <Card>
              <div className="card-header pb-0">
                {/* <H4>Scan QR</H4> */}
                {/* <span>lorem ipsum dolor sit amet, consectetur adipisicing elit</span> */}
              </div>
              <CardBody>
                {!modal &&
                  <QrReader
                    // delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: "100%" }}
                    constraints={{
                      // audio: true,
                      video: { facingMode: "environment", zoom: 2 },
                    }}
                  />
                }
              </CardBody>
            </Card>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default Index